<template>
    <div v-if="isLoading">
        <div class="text-center text-danger screen-center" style="padding-top: 20%;">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-sm-5">
                <div class="breadcrumb">
                    <h1>Nueva Empresa</h1>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="col-sm-5">
                            <button type="button" class="btn th-custom-color" @click="store()">
                                Guardar
                            </button>
                            <router-link :to="{ name: 'empresa-index' }">
                                <button
                                    style="margin-left: 10px;"
                                    class="btn btn-white border border-light">
                                    Regresar
                                </button>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Nombre Comercial:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    disabled
                                                    v-model="nombreComercial"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Razon Social:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    disabled
                                                    v-model="razonSocial"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Origen:</label>
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" v-model="origenUrl" disabled>
                                            </div>
                                            <div class="col-sm-2 pr-0">
                                                <label class="col-form-label">Id:</label>
                                            </div>
                                            <div class="col-sm-2" style="right:65px;">
                                                <input type="text" class="form-control" placeholder="Id" disabled>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Nit:</label>
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="nit"
                                                    disabled
                                                >
                                            </div>
                                            <div class="col-sm-3 pr-0">
                                                <label class="col-form-label">Ciudad:</label>
                                            </div>
                                            <div class="col-sm-3" style="right:65px;">
                                                <input type="text" class="form-control" disabled v-model="ciudadSelected">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2" style="background: #dee2e6;">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Entorno:</label>
                                            </div>
                                            <div class="col-sm-5">
                                                <multiselect
                                                    :options="entornos"
                                                    v-model="entornoSelected"
                                                    track-by="id"
                                                    label="nombre"
                                                    placeholder="Entorno"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                                
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="text" disabled value="" class="form-control" placeholder="Id Medico">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Razon Social:</label>
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" disabled placeholder="Razon Social Medico">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2 row2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Token Delegado:</label>
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="password"
                                                    class="form-control"
                                                    v-model="tokenDelegado"
                                                    disabled
                                                    autocomplete="off" name="token_delegado" id="token_delegado"
                                                    required
                                                >
                                                
                                            </div>
                                            <div class="col-sm-1">
                                                <button class="btn th-custom-color" type="button" @click="openModal()">
                                                    +
                                                </button>
                                            </div>
                                            <div class="col-sm-1" style="margin:8px;">
                                                <input type="checkbox"
                                                v-model="verTokenDelegado">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Vencimiento de Token:</label>
                                            </div>
                                            <div class="col-sm-6">
                                                <input type="text" v-model="vencimientoToken" disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Proveedor de Software:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <multiselect
                                                    :options="proveedorSoftwares"
                                                    v-model="proveedorSoftwareSelected"
                                                    track-by="id"
                                                    label="proveedor_software"
                                                    placeholder="Proveedor"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Paquete:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <multiselect
                                                    v-model="paqueteSelected"
                                                    :options="paquetes"
                                                    track-by="nombre"
                                                    label="nombre"
                                                    placeholder="Paquete"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Facturacion:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <multiselect
                                                    v-model="facturacionSelected"
                                                    :options="facturacionTipos"
                                                    track-by="nombre"
                                                    label="nombre"
                                                    placeholder="Facturacion"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Documento por Sector:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <multiselect
                                                    v-model="documentoSelected"
                                                    :options="tipoDocumentos"
                                                    track-by="id"
                                                    label="descripcion"
                                                    placeholder="Documento por sector"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    multiple
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Ambiente:</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <multiselect
                                                    v-model="ambienteSelected"
                                                    :options="ambientes"
                                                    track-by="nombre"
                                                    label="nombre"
                                                    placeholder="Ambiente"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Importe:</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <vue-numeric v-model="importe" 
                                                    v-bind:minus="false" v-bind:min="0" 
                                                    v-bind:precision="2"
                                                class="form-control"/>
                                            </div>
                                            <div class="col-sm-4">
                                                <multiselect
                                                    v-model="monedaSelected"
                                                    :options="monedas"
                                                    track-by="id"
                                                    label="descripcion"
                                                    placeholder="Moneda"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Cantidad Timbre:</label>
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="text" class="form-control" v-model="cantidadTimbre">
                                            </div>
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Confirmar Timbre    :  </label>
                                            </div>
                                            <div class="col-sm-3 pl-0" style="margin-right;: inherit">
                                                <input type="text" class="form-control" v-model="confirmarTimbre">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Tiempo</label>
                                            </div>
                                            <div class="col-sm-3">
                                                <multiselect
                                                    v-model="tiempoSelected"
                                                    :options="tiempos"
                                                    track-by="nombre"
                                                    label="nombre"
                                                    placeholder="tiempos"
                                                    deselectLabel=""
                                                    selectLabel=""
                                                    :searchable="true"
                                                    @select="showExpirationDate"
                                                    >
                                                </multiselect>
                                                <span v-show="tiempoSelected">Vence: {{fechaVencimiento}} </span>
                                            </div>
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label" style="text-align: right">Actual    :</label>
                                            </div>
                                            <div class="col-sm-3 pl-0" style="margin-right: inherit">
                                                <input type="text" class="form-control" v-model="cantidadActual" placeholder="0/500">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Fecha Inicio :</label>
                                            </div>
                                            <div class="col-sm-3">
                                                <date-picker
                                                    style="width:120px;"
                                                    v-model="fecha"
                                                    lang="es"
                                                    format="DD/MM/YYYY"
                                                    type="date"
                                                    value-type="date"
                                                ></date-picker>
                                            </div>
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Fecha Final    :</label>
                                            </div>
                                            <div class="col-sm-3 pl-0" style="margin-right: inherit">
                                                <date-picker
                                                    style="width:130px;" 
                                                    v-model="fechaFinal"
                                                    lang="es"
                                                    format="DD/MM/YYYY"
                                                    type="date"
                                                    value-type="date"
                                                ></date-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <theModalListToken
            @set-list-token="setListToken"/>
    </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

import VueNumeric from 'vue-numeric'
import { call, get, sync } from 'vuex-pathify';
import theModalListToken from '../components/theModalListToken.vue';

export default {
    name:'create',
    components: {
        DatePicker,
        Multiselect,
        VueNumeric,
        theModalListToken,
    },
    data() {
        return {
            isLoading: false,
            verTokenDelegado: false,
        };
    },
    created() {
        this.getDatosInicial();
    },
    methods: {
        async store() {
            this.isLoading = true;
            const STORE_OK = await this.storeEmpresa();
            if (STORE_OK) {
                this.$router.push({ name: 'empresa-index' });
            }
            this.isLoading = false;
        },
        showExpirationDate(item) {
            if (item.id === 1) {
                // tiempo anual
                this.fechaVencimiento = moment().add(1, 'year').format("DD/MM/YYYY");
            }
            if (item.id === 2) {
                // tiempo mensual
                this.fechaVencimiento = moment().add(1, 'months').format("DD/MM/YYYY");
            }
        },
        ...call('empresa/generateCodigoIntegracion', [
            'generarCodigo'
        ]),
        ...call('empresa/empresaStore', [
            'getDatosInicial',
            'storeEmpresa',
        ]),
        setListToken(dtos) {
            this.tokenDelegado = null;
            this.vencimientoToken = null;
            dtos.forEach(element => {
                if (element.is_active === 1) {
                    this.tokenDelegado = element.token;
                    this.vencimientoToken = element.vence;
                }
            });
        },
        openModal() {
            this.$bvModal.show('modal-token');
        },
    },
    computed: {
        ...sync('empresa/empresaStore', [
            "nombreComercial",
            "razonSocial",
            "nit",
            "fecha",
            "fechaFinal",
            "origenUrl",
            "entornoSelected",
            "ciudadSelected",
            "paqueteSelected",
            "facturacionSelected",
            "documentoSelected",
            "importe",
            "monedaSelected",
            "cantidadTimbre",
            "confirmarTimbre",
            "tiempoSelected",
            "cantidadActual",
            "fechaVencimiento",
            "ambienteSelected",
            "proveedorSoftwareSelected",
            'tokenDelegado',
            'vencimientoToken'
        ]),
        ...get('empresa/empresaStore', [
            "paquetes",
            "facturacionTipos",
            "tipoDocumentos",
            "monedas",
            "tiempos",
            "proveedorSoftwares",
            "ambientes",
            "entornos"
        ]),
    },
    watch: {
        verTokenDelegado(new1) {
            if (new1) {
                document.getElementById("token_delegado").type  = "text";
            } else {
                document.getElementById("token_delegado").type  = "password";
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('empresa/empresaStore/reset');
    }
}
</script>
<style scoped>
.row2 {
    background:blanchedalmond; 
    margin:auto; 
    padding-top: 7px;
}
.btn-yellow {
    color: gray;
    background-color: yellow;
}

</style>